.registrationContainer {
  width: 90%;
  margin: 35% 5%;
  text-align: center;
  background-color: rgb(215, 205, 204);
  border: 2px solid rgb(73, 36, 69);
  padding: 6%;
}

form > div > h2 {
  padding-bottom: 6%;
}

form > div > input {
  text-align: center;
  background-color: rgb(215, 205, 204);
  margin-bottom: 1%;
  width: 80%;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(124, 124, 124);
  color: rgb(73, 36, 69);
}

input:focus {
  border-bottom: 1.5px solid rgb(73, 36, 69);
  box-shadow: 1px gray;
}

#retype_register_password {
  margin-bottom: 6%;
}

.userRedirect {
  margin-top: 3%;
  font-size: 12px;
  opacity: 0.4;
}

.registrationButton {
  width: 30%;
  padding: 1.5%;
  background-color: white;
  border: 2px solid rgb(73, 36, 69);
  color: rgb(73, 36, 69);
}

@media only screen and (min-width: 1024px) {
  .registrationContainer {
    width: 50%;
    margin: 20% auto;
    text-align: center;
    padding: 3%;
  }
}