header {
  width: 100%;
  position: fixed;
  padding: 0 0 2%;
  top: 0;
  border-bottom: 2px solid rgb(73, 36, 69);
  background-color: white;
  z-index: 999;
}

h2 {
  color: rgb(73, 36, 69);
}

.headerLink {
  text-decoration: none;
  color: rgb(73, 36, 69);
}

.headerLink:hover {
  cursor: pointer;
}

.headerContainer {
  padding-top: 3%;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.user__logout {
  align-self: flex-end;
  margin-bottom: 0.8%
}

.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 3px;
  background-color: rgb(73, 36, 69);
  margin: 4px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 4px);
  transform: rotate(-45deg) translate(-6px, 4px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -5px);
  transform: rotate(45deg) translate(-5px, -5px);
}

.dropdownContent {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: -5px;
}

.dropdownContent span {
  color: black;
  padding: 12px 16px;
  display: block;
}

.logout_user_link {
  text-decoration: none;
}

.user_home_link {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5.5px, -5.5px);
    transform: rotate(45deg) translate(-5.5px, -5.5px);
  }
}