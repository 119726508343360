footer {
  height: 2.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.footerContainer {
  width: 80%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2% auto;
}

.footerContainer > p {
  font-size: 16px;
}

.contactIcons {
  text-decoration: none;
}

.contactIcons:hover {
  cursor: pointer;
}

ion-icon {
  font-size: 24px;
  color: rgb(73, 36, 69);
}