.landingPage { 
  width: 100%;
  height: 100%;
  margin-top: 12.5%;
}

.landingPageHeader {
  color: white;
  padding: 25% 20% 5% 5%;
  width: 100%;
  height: 100%;
  background: url(../../nice-restaurant.jpg);
  background-position: 80% 80%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.welcomeBox {
  width: 80%;
  position: relative;
  background-color: rgba(73, 36, 69, 0.8);
  color: white;
  padding: 20px 20px;
}

.welcomeBox > div {
  width: 80%;
  margin-top: 8%;
}

.landingPageDescriptions {
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.landingPageButton {
  background-color: rgb(73, 36, 69);
  color: white;
  border: 1px solid white;
  margin: 2%;
  padding: 3%;
}

.descBox {
  padding: 5%;
  margin: 2% 0;
  background-color: rgb(73, 36, 69);
  color: white;
}

h3 {
  color: rgb(215, 205, 204);
  margin-bottom: 2%;
  text-align: center;
}

.modal {
  position: fixed;
  z-index: 1; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  padding: 2px 16px;
}

.modal-header {
  padding: 2px 16px;
  background-color: rgb(73, 36, 69);
  color: white;
}

.modal-header-title {
  color: white;
}

.modal-footer {
  text-align: center;
  padding: 2px 16px;
  background-color: rgb(73, 36, 69);
  color: white;
}

@media only screen and (min-width: 768px) {
  .landingPage {
    margin-top: 8.5%;
  }

  .welcomeBox {
    width: 50%;
    padding: 35px 35px;
  }

  .modal-header-title {
    margin: 3% 0;
  }

  .modal-footer {
    padding: 0%;
  }

  .modalButton {
    width: 25%;
    height: 10%;
  }
}

@media only screen and (min-width: 1024px) {
  .landingPage {
    margin-top: 7.8%;
  }

  .landingPageDescriptions {
    padding: 3% 3%;
    flex-direction: row;
    justify-content: space-around;
  }

  .welcomeBox {
    width: 60%;
    padding: 40px 40px;
  }

  .descBox {
    padding: 3%;
    margin: 0% 1%;
  }

  .descBox > h3 {
    margin-bottom: 5%;
    font-size: 22px;
  }

  .descBox > p {
    font-size: 18px;
  }

  h1 {
    font-size: 42px;
  }

  .modalButton {
    width: 25%;
    height: 5%;
    padding: 1%;
    margin: 2%;
  }
}